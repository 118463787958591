<template>
  <TTView>
    <VRow>
      <VCol>
        <TemplateForm
          :entity="template"
          @update:name="template.name = $event"
          @update:subject="template.subject = $event"
          @update:htmlTemplate="template.htmlTemplate = $event"
          @update:textTemplate="template.textTemplate = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_TEMPLATE } from '../../components/notification/common';
import TemplateForm from '../../components/notification/TemplateForm.vue';

export default {
  name: 'TemplatesCreate',

  components: {
    TemplateForm,
  },

  inject: ['Names'],

  data() {
    return {
      template: { ...DEFAULT_TEMPLATE },
      loading: false,
    };
  },

  methods: {
    handleSubmit() {
      this.loading = true;
      const { template } = this;
      const { name } = template;

      delete template.name;

      this.$di.api.Notification
        .TemplatesSet({ name, template })
        .then(() => {
          this.$di.notify.snackSuccess('Шаблон создан');
          this.$router.push({
            name: this.Names.R_NOTIFICATION_TEMPLATES,
          });
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
